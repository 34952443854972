.modalBackground {
    /* 
    background-color: rgb(243, 247, 247);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; */
    position: absolute;
    z-index: 999;
    width: 100vw;
    height: 100vh;
  }
  
  .modalContainer {

    opacity: 1;
    z-index: 1000;
    width: 100%;
    height: 100%;
    
    /* padding: 25px; */
  }
  
  .modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }
  
  .modalContainer .body {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
  } 
  
  .modalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
  }
  
  #cancelBtn {
    background-color: crimson;
  }