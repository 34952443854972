*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.custom-form-btn{
    max-width: 200px;
    margin: inherit;
    margin-top: 15px;
    margin-bottom: 15px;
}
.cont-title-hed {
    padding: 10px;
    background: #f1ecf0;
    color: #fff;
    margin-bottom: 15px;
}
.container {
    padding-right: 10px;
    padding-left: 10px;
}
.padding_30{
    padding-left: 30px;
    padding-right: 20px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
}
.pd-30{
    padding:30px 0;
}
.pd-z{
    padding:0 !important;
}
.Custom-title{
    color: #000;
    font-size: 14px;
    text-align: center;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: bold;
}
p{
    margin: 0 0 10px;
}
.hr-line{
    width: 30%;
    margin-left: 33%;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 0;
    border-top: 2px solid #eee;
    border-image: linear-gradient(to left,#fff 1%,#502c1e 50%,#fff 100%) 0 0 100% 0/0 0 3px 0 stretch
}

/* !Left Side Form CSS Start */
.custom-form{
    background-color: #fff;
    box-shadow: 0 0 5px 0 #80808082;
    border-radius: 5px;
}
.form-group{
    margin-bottom: 15px;
    padding: 2px 13px;
}
.d-block{
    display: inline-block;
    padding: 5px;
}
.d-block label{
    font-size: 14px;
}
input[type=radio] {
    border: 0px;
    height: 10px;
}
/* .row {
    margin-right: 0px;
    margin-left: -1px;
} */


label {
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
   
}
.mark_red{
    color: red;
}
.mark_black{
    color:#000
}
hr{
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid rgb(155, 150, 150);
}
.from_Group label{
    font-size: 14px;
}
.m{
    margin-left: 15px;
    width: 95%;
}

.fix-left{
    position: sticky;
    top: 0px;
}
.input_check{
    margin-left: 20px;
    margin-right: 7px;
}


.font-12{
    font-size: 12px;
    margin-bottom: 12px;
}
.d-block label input{
    margin-right: 10px;
}
/* !Left Side Form CSS End */



/* !Right Side Form CSS Start */
.custom_box{
    background-color: #fff;
    box-shadow: 0 0 5px 0 #80808082;
    border-radius: 5px;
}
.text-center{
    text-align: center;
}
.padding{
    padding: 4px 16px;
}
.contact_title{
    padding: 10px;
    /* background: #590004; */
    color: #fff !important;
    margin-bottom: 15px;
}
.font-18{
    font-size: 18px;
}
.mb-30{
    margin-bottom: 30px;
}
.from_Group{
    margin-bottom: 15px;
}
.form_control{
    display: block;
    width: 100%;
    height: 45px;
    padding: 3px 12px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    /* border-radius: 4px; */
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.input_group{
    position: relative;
    display: table;
    border-collapse: separate;
}
.input-group:focus {
    border-color: #a40001;
    outline: 0;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px #dedede;
}
.input-group-addon:first-child {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group-addon {
    padding: 6px 12px;
    font-size: 12px;
    font-weight: normal;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
}
.input_group .form_control:last-child{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-group-addon{
    display: table-cell;
}
.input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
    height: 34px;
    padding: 1px 12px;
    font-size: 11px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
textarea {
    resize: none;
}
textarea.form_control {
    height: auto;
}
.mrgin-top{
    margin-top: 40px;
}
.mx-400{
    width:320px
}
.mb-10{
    margin-bottom: 10px;
}
.mt-10{
    margin-top: 10px;
}
.submit-btn{
    max-width: 200px;
    margin: inherit;
    margin-top: 15px;
    margin-bottom: 15px;
}
.submit{
    width: 100%;
    background: #a40001;
    border-radius: 4px;
    border: 0;
    cursor: pointer;
    color: #fff;
}
.file_up{
    display: none;
}
.img_btn{
    /* top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: linear-gradient(to right,#df2626,#a40001);
    border-radius: 5px;
    color: #fff;
    letter-spacing: 1px;
    padding-top: 10px;
    padding-bottom: 10px; */
}
.img{
    width: 200px;
    padding: 20px 10px;
    
}
@media (max-width:767px) {
    .custom_box{
        margin-top: 10px;
    }
    
}
/* !Right Side Form CSS End */

.none_display {
    display: none;
  }
  .option_item .checkbox:checked ~ .option_inner.main {
    border-color: #2825c4;
    /* z-index: 99999; */
  }
  img .img_opacity:checked{
    filter: brightness(0) invert(1);
  }
  





/* !Guide text CSS Start */
.h4_font{
    font-size: 16px;
}
.text-deco {
    text-decoration: underline!important;
}
.pd-start{
    padding-inline-start: 15px;
}
ol{
    margin-top: 0;
    margin-bottom: 10px;
}
ol li{
    margin-bottom: 10px;
    font-size: 13px;
}


/* !Guide text CSS END */



/* Style the tab */
.tab {
    overflow: hidden;
    /* border: 1px solid #ccc; */
    /* background-color: #f1f1f1; */
  }
  
  /* Style the buttons that are used to open the tab content */
  .tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
  }
  
  /* Change background color of buttons on hover */
  .tab button:hover {
    background-color: #ddd;
  }
  
  /* Create an active/current tablink class */
  .tab button.active {
    /* background-color: #ccc; */
    background-color: #502C1E;
    color:white !important
  }
  
  /* Style the tab content */
  .tabcontent {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
    background-color: #F2F2F2;
  }
  .custom-diam-shape{
    box-shadow: 0 1px 4px 0 rgba(89,0,4,0.2);
    margin: 3px 5px 0 0;
    border-radius: 5px;
    margin-bottom: 7px;
    border: 1px solid transparent;
    line-height: 25px;
    text-align: center;
    min-width: 43px;
    padding: 2px 7px 1px 5px;
    cursor: pointer;
  }

  .add-non-active {
    box-shadow: 0 1px 4px 0 rgba(89,0,4,0.2);
    margin: 3px 5px 0 0;
    border-radius: 5px;
    margin-bottom: 7px;
    border: 1px solid transparent;
    line-height: 25px;
    text-align: center;
    min-width: 50px;
    padding: 2px 7px 1px 5px;
    cursor: pointer;
    font-weight: normal;
  }
  .add-active {
    border: 1px solid #502C1E!important;
    font-weight: bold!important;
}
.bg-fff {
    background: #fff;
}
.chk-sign input:checked ~ .checkmark{
    background-color: #8F786F !important;
}
#tab-collection-section .tab-seaction .tab-content .tab-pane{
    background-color: #f2f2f2 !important;
}