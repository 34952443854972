
.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  padding: 30px;

}

.paginationItem {
  background: #fff;
  border: 1px solid #666;
  padding: 10px 15px;
  position: relative;
  margin: 0 5px;
  cursor: pointer;
}

.paginationItem:hover {
  background: #f2ecec;
}

.paginationItem span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.prev,
.next {
  background: #fff;
  border: none;
  padding: 10px;
  color: #ca9e79;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin: 0 10px;
  cursor: pointer;
}
.paginationItem.active {
  border: 1px solid #888;
  color: #888;
  pointer-events: none;
}

.prev.disabled,
.next.disabled {
  pointer-events: none;
  box-shadow: none;
  color: #999;
}
.nav-item .nav-link:hover{
  color: #ca9e79 !important;
}
a:hover {
  color: #ca9e79;
  text-decoration:none;
}
.card {
  display: inline-block;
  width: 400px;
  height: 250px;
  margin: 1rem;
  flex: 0.5;
  text-align: center;
  background: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1rem;
}
.diamondcard {
  display: inline-block;
  width: 40px;
  height: 25px;
  margin: 1rem;
  flex: 0.5;
  text-align: center;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.MuiPaginationItem-outlinedPrimary.Mui-selected {
  color: #ca9e79 !important;
  border: 1px solid #ca9e79 !important;
  background-color: #fff !important;
}
.currency-code{
  position:absolute;
  padding-top: 15px;
  padding-left: 3px;
}
.currency-wrap{
  position:relative;
}
.highlightOption{
  background: #fff !important;
  color: #000 !important;
}
.multiSelectContainer li:hover {
  background: #f1ecf0 !important;
  color: #000 !important;
  cursor: pointer !important;
}
/* .nav-link{
  display:unset
} */

#search.open {
  opacity: 1;
  z-index: 1000;
  width: 100%;
  height: 100%;
}

.autocomplete-box {
  position: absolute;
  /* border-bottom: none; */
  border-top: none;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
  overflow: auto;
  height: 300px;
  background-color: #fff;
}

.selected-boxdiv{
  border: 1px solid #ebebeb;
    padding: 15px 20px 10px 20px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 1px 1px 3px 0px #f1f1f1;
}
.form-control1{
  display: block;
    width: 100%;
    /* height: calc(1.5em + 0.75rem + 2px); */
    /* padding: 0.375rem 0.75rem; */
    /* font-size: 1rem; */
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.search_btn_div{cursor:pointer;font-size:22px;line-height:1;vertical-align:middle;}
/* .search-text{border-bottom:0.1px solid #d2c5c5 !important;border:none !important;box-shadow:none;border-radius:0;width:100%;padding:10px 10px 5px 40px;font-size:18px;background:transparent;} */
/* .header_search form:before{content:"";position:absolute;color:#d2d0cd;font-size:22px;background:url(./assets/images/search.png) no-repeat;width:25px;height:28px;} */

#search .close {
  background-color: #fff;
  color: #252525;
  font-size: 40px;
  line-height: 20px;
  opacity: 1;
  padding: 5px 10px;
  position: fixed;
  right: 30px;
  text-align: center;
  top: 30px;
}

  /* The container */
  .checkboxs {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .checkboxs input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  .checkboxs:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .checkboxs input:checked ~ .checkmark {
    background-color: #f1ecf0;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkboxs input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkboxs .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid Black;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
.shapeImg{
  width:25px
}
.searchImg{
  width:50px;
  height: 40px;
}
.css-187mznn-MuiSlider-root{
  border-radius: 12px;
  box-sizing: content-box;
  display: inline-block;
  position: relative;
  cursor: pointer;
  touch-action: none;
  color: #f1ecf0 !important;
  -webkit-tap-highlight-color: transparent;
  height: 4px;
  width: 100%;
  padding: 13px 0;
}
.css-exkjcx{
  color: #f1ecf0 !important;
}
.css-eg0mwd-MuiSlider-thumb:hover, .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible{
  box-shadow:0px 0px 0px 8px rgb(243, 228, 216) !important;
}



.autocomplete-box div{
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
}
.autocomplete-items:hover{
  border-left: 1px solid #d4d4d4;
  border-right: 1px solid #d4d4d4;
  background-color: #f1ecf0 !important;
}
.searchList{
  padding: 10px;
  background-color: #eee;
}

th a.sort-by { 
	padding-right: 18px;
	position: relative;
}
a.sort-by:before{
	border: 4px solid transparent;
	content: "";
	display: block;
	height: 0;
	right: 5px;
	top: 50%;
	position: absolute;
	width: 0;
}
a.sort-by:after {
	border: 4px solid transparent;
	content: "";
	display: block;
	height: 0;
	right: 5px;
	top: 50%;
	position: absolute;
	width: 0;
}
a.sort-by:before {
	border-bottom-color: #666;
	margin-top: -9px;
}
a.sort-by:after {
	border-top-color: #666;
	margin-top: 1px;
}
.radio-btn {
  margin: 10px;
  padding: 10px;
  border-radius: 15px;
}
.chip, .singleChip{
  white-space:unset !important
}
/* .braintree-dropin * { */
  /* display: block; */
/* } */

/* back-to-top */
#backtotopbutton {
  display: inline-block;
  background-color: #FF9800;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color .3s, 
    opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}
#backtotopbutton::after {
  content: "\f077";
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  font-size: 2em;
  line-height: 50px;
  color: #fff;
}
#backtotopbutton:hover {
  cursor: pointer;
  background-color: #333;
}
#backtotopbutton:active {
  background-color: #555;
}
#backtotopbutton.show {
  opacity: 1;
  visibility: visible;
}

/* Styles for the content section */

.content {
  width: 77%;
  margin: 50px auto;
  font-family: 'Merriweather', serif;
  font-size: 17px;
  color: #6c767a;
  line-height: 1.9;
}
@media (min-width: 500px) {
  .content {
    width: 43%;
  }
  #backtotopbutton {
    margin: 30px;
  }
}
.content h1 {
  margin-bottom: -10px;
  color: #03a9f4;
  line-height: 1.5;
}
.content h3 {
  font-style: italic;
  color: #96a2a7;
}

.offer_box{
    
  margin-top: '64px';
}

@media (max-width:786px) {
  .offer_slider {

      display: none;
  }
}

@media (min-width: 789px){
  .offer_box{
      display: none;
  }
}
.top-header-flex .header-top-ul ul li a{
  text-decoration: none;
  background-color: transparent;
  color: inherit !important;
}
.top-header-flex .header-top-ul ul li a:hover{
  color: #ca9e79 !important;
  text-decoration: none;
}

.panel,
.panel-body {
  box-shadow: none;
}

.panel-group .panel-heading {
  padding: 0;
}

.panel-group .panel-heading a {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  position: relative;
}

.panel-group .panel-heading a:after {
  content: '-';
  float: right;
}

.panel-group .panel-heading a.collapsed:after {
  content: '+';
}

.panel-body {
  padding: 1rem;
}
.panel-group .panel-heading a{
  color: #838383 !important; 
}
.panel-title{
  background: #f9f9f9;
  font-size: 1rem;
}

.active-tab{
  background: #ca9e79 !important;
  border : 1px solid #ca9e79 !important;
}

.deactive-tab{
  border : 1px solid #ca9e79 !important;
}