.modal-backdrop {
    z-index: 0 !important;
}

 /* width */
 .metal-list::-webkit-scrollbar {
    height: 2px;
  }
  
  /* Track */
  .metal-list::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .metal-list::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  .metal-list::-webkit-scrollbar-thumb:hover {
    background: #555;
  }


