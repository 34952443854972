.bg-color {
  background-color: #f2f2f2;
}

/*.add-non-active {
  box-shadow: 0 1px 4px 0 rgb(89 0 4 / 20%);
  margin: 3px 5px 0 0;
  border-radius: 13px;
  margin-bottom: 7px;
  border: 2px solid transparent;
  line-height: 25px;
  text-align: center;
  min-width: 43px;
  padding: 1px 9px 0px 9px;
  cursor: pointer;
  font-weight: normal;
  background: #fff;
}*/
.ml-3{
  margin-left: 10px;
  font-size: 14px;
  padding-top: 10px;
}
.max-shape-w {
  max-width: 36px;
  height: 36px;
}
img {
  vertical-align: middle;
}
.title-shape {
  font-size: 12px;
}
.new-pad-box {
  padding: 3px 7px 2px 5px;
}
.form-con {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.float{
  max-width: 76px;
  float: left;
  margin-left: 10%;
}
.float1{
  max-width: 76px;
  float: right;
  margin-right: 10%;
}

.mb{
  margin-bottom: 20px;
}
.slider{
  margin-left: 10%;
  max-width: 80%;
}

/* !SideStone CSS start */
.d-flex{
  display: flex;
}
.text-right{
  text-align: right !important;
}
.pdd{
  padding: 6px 15px;
}
.new-add-btnn {
  color: #fff;
  background-color: #df2626;
  padding: 10px 30px;
  border-radius: 0;
  border-color: transparent;
  border: 0;
  margin: 10px 0;
  font-weight: bold;
}
.btnn{
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.table-responsive {
  min-height: .01%;
  overflow-x: auto;
}
.table {
  width: 100%;
  /* max-width: 100%; */
  margin-bottom: 20px;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  /* width: 100%; */
  border: 1px solid #ddd;
  background-color: transparent;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.w-9{
  width: 9%;
}
.w-16{
  width: 16%;
}
.w-20{
  width: 20%;
}
.w-11{
  width: 11%;
}
.w-15{
  width: 15%;
}
.w-30{
  width: 30%;
}
.w-25{
  width: 25%;
}
.text-center {
  text-align: center;
}
.bg-red{
  color: red;
  cursor: pointer;

}
/* !SideStone CSS end */